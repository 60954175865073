.AdultEvent1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 0px 0px 20px 0px;
}

.EventImage {
    width: 100vw;
    height: 40vh;
    object-fit: cover;
    border-radius: 20px;
}

.ScheduleContainer {
    display: flex;
    justify-content: space-between;
    gap: 100px;
    background-color: #aebea7;
    border-radius: 20px;
    padding: 50px;
}

.Headline {
    font-size: 1.8em;
    margin-bottom: 0.5em;
    margin-top: 1em;
    text-align: center;
}

.Date {
    font-size: 1.3em;
    margin-bottom: 0.5em;
}

.Dag {
    margin: -10px 0px 20px 0px;
    text-align: center;
}

.Description {
    margin-bottom: 1em;
}

.InfoList {
    list-style-type:disc;
    padding: 0;
}

.InfoItem {
    margin-bottom: 0.5em;
}

.HeroButton {
    margin: 20px auto;
    background-color: #8b9d83;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
}

.Center {
    margin-top: 0.5em;
    text-align: center;
    font-weight: bold;
    justify-content: center;
}


/* Media */

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .EventImage {
        margin-top: -20px;
    }
}

@media only screen and (max-width: 991px) {
    .AdultEvent1  {
        text-align: left;
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 424px) {
    .ScheduleContainer {
        flex-direction: column;
        justify-content: space-between;
        gap: 0px;
        width: 90vw;
    }
}