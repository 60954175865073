.Contact {
    width: 30vw;
    margin: auto;
    margin-top: 19px;
    justify-content: center;
}

.Image {
    margin-bottom: 10px;
    border-radius: 20px;
    width: 100%;
}


@media only screen and (max-width: 1024px) {
    .Contact {
        width: 50vw;

    }
}


@media only screen and (max-width: 768px) {
    .Contact {
        width: 70vw;
    }
}

@media only screen and (max-width: 490px) {
    .Contact {
        width: 80vw;
    }
}