.NavBar {
  background-color: #8b9d83;
}

.NavLink {
  padding: 5px;
  color: black;
}

.NavLink:hover {
  text-decoration: none;
  color: white;
}

.NavLink i {
  padding-right: 5px;
}

.img {
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  margin: auto;
}

.Active i {
  color: whitesmoke;
}

.BrandText {
  margin-left: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #2b2118;
}
