.Button {
    font-size: 0.8rem;
    align-self: center;
    border-radius: 15px;
    border-color: transparent;
    padding: 4px 10px;
    margin: 5px 2px;
    min-width: 75px;
}

.Button:hover {
    opacity: 0.8;
    border-color: transparent;
    cursor: pointer;
}

.Button:hover:active {
    background-color: #2142b2 !important;
}

.Button:active {
    background-color: #2142b2 !important;
}

.Button:focus {
    background-color: #2142b2;
}

.Wide {
    padding: 10px 50px;
    width: 100%;
}

.Grey {
    background-color: grey;
    color: #ffffff;
    border: solid 1px grey;
}

.Grey:hover {
    color: grey;
    background-color: #ffffff;
    border: solid 1px grey;
}

.Blue,
.BlueOutline:hover,
.Bright:hover {
    background-color: #2142b2;
    color: #ffffff;
    border: 1px solid #2142b2;
}

.BlueOutline,
.Blue:hover {
    border: 1px solid #2142b2;
    background-color: #ffffff;
    color: #2142b2;
}

.Black,
.BlackOutline:hover {
    background-color: #242a3d;
    color: aliceblue;
}

.Black:hover,
.BlackOutline {
    background-color: #dadadf;
    color: #242a3d;
    border-color: #242a3d;
}

.Bright {
    background-color: #2ed9ff;
    color: #2142b2;
}

.Round {
    border: none;
    border-radius: 50%;
    background-color: #22199e;
    color: #fff;
    width: 80px;
    height: 80px;
    text-decoration: none;
    font-size: 16px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Round:hover {
    background-color: #0056b3;
}


.Gray {
    background-color: #968E85;
    padding: 20px;
    border: 2px solid #968E85;
    border-radius: 50px;
    color: white;
    width: 150px;
}

.Gray:hover {
    color: #968E85;
    background-color: white;
}