.App {
    font-family: "Roboto", sans-serif;
    min-height: 100vh;
    background-color: #ECF0F1;
    color: #2B2118;
}

.Main {
    padding-top: 71px;
}

a {
    color: #2B2118;
}

a:hover {
    text-decoration: none;
}

i {
    text-decoration: none;
    color: #2B2118;
}

i:hover {
    cursor: pointer;
}

.Content {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #dadadf;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .Main {
        padding-top: 90px;
    }
}