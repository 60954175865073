.Events {
    display: block;
    text-align: center;
    justify-content: center;
    background-color: #aebea7;
    border-radius: 10px;
    padding: 20px;
    margin: 50px 0px;
}

.Headline {
    text-align: center;
    font-size: 1.5rem;
}

.Date {
    text-align: center;
    justify-content: center;
    font-size: 1rem;
}

.Content {
    justify-content: center;
    text-align: start;
    font-size: 0.9rem;
}

.HeroButton {
    display: block;
    justify-content: center;
    margin: 20px auto;
    background-color: #35393C;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
}


@media only screen and (min-width: 1024px) {
    .Container {
        display: flex;
        justify-content: center;
        
    }
    .Events {
        width: 50%;
    }
}